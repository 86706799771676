import React, { useEffect, useState } from "react";
import "./AllResources.css";
import { AVAILABILTY_NOTE, CHOOSE_NOTE } from "../../../utils/constants";
import { Col, Input, Pagination, Row, Skeleton, Switch, Table } from "antd";
import {
  makeBuyerDataList,
  makeSellerDataList,
} from "../../../redux-core/categorylist/selectors";
import { createSelector } from "reselect";
import {
  getAllBuyersData,
  getAllSellersData,
} from "../../../redux-core/categorylist/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SellerData from "./sellerdata/SellerData";
import BuyerData from "./buyerdata/BuyerData";
import * as XLSX from 'xlsx';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}


const AllResources = () => {
  const [switchForSeller, setSwitchForSeller] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;

  const onChange = (checked) => {
    console.log("check", checked);
    setSwitchForSeller(checked);
  };

  return (
    <div className="category-container">
      <h5 style={{ fontWeight: "bold", fontSize: "13px" }}>
        {AVAILABILTY_NOTE}
        {CHOOSE_NOTE}
        <span className="buyerSellerSwitch_Container">
          <b>Buyer</b>
          <Switch
            className="buyerSellerSwitch"
            defaultChecked
            onChange={onChange}
          />
          <b>Seller</b>
        </span>
      </h5>
      <br />
      {switchForSeller ? <SellerData /> : <BuyerData />}
    </div>
  );
};

export default AllResources;
