import { Button, Card, Form, Input } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addLocation } from "../../redux-core/locationrequest/actions";


const actionDispatch = (dispatch) => ({
  addLocationList: (payload) => dispatch(addLocation(payload))
});

const AddCity = (props) => {
  const { addLocationList } = actionDispatch(useDispatch());
  const formRef = React.createRef();
  const [locationName,setLocationName]=useState(props.locationName);

   //useEffect for update the location name
  useEffect(()=>{
    setLocationName(props.locationName);
  },[props.locationName]) 

  //useEffect for updating the current form item input field for location
  useEffect(()=>{
    formRef.current.setFieldsValue({city:locationName})
  })

  //setting default value 
  const setDefaultState = () => {
    formRef.current.setFieldsValue({ city: '', latitude: '', longitude: ''});
}

  const addCityData=(val)=>{
    const payload = {
        city_name: val.city,
        latitude:val.latitude,
        longitude:val.longitude
    }
    // console.log('pp',payload)
    addLocationList(payload);
    setDefaultState();
  }

  return (
    <>
      <Card hoverable={true} className="userFormCard">
        <Form onFinish={addCityData} ref={formRef}>
          <Form.Item
            label="City"
            name="city"
            value={locationName}
            rules={[{ required: true, message: "Please enter city name" }]}
          >
            <Input  type="text" placeholder="Please add city"  />
          </Form.Item>

          <Form.Item
            label="Latitude"
            name="latitude"
            rules={[{ required: true, message: "Please enter latitude name" }]}
          >
            <Input type="text" placeholder="Please add latitude" />
          </Form.Item>

          <Form.Item
            label="Longitude"
            name="longitude"
            rules={[{ required: true, message: "Please enter longitude name" }]}
          >
            <Input type="text" placeholder="Please add longitude" />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddCity;
