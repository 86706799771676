import React, { useEffect, useState } from "react";
import { AVAILABILTY_NOTE, CHOOSE_NOTE } from "../../../../utils/constants";
import { Button, Col, Input, Pagination, Row, Skeleton, Switch, Table } from "antd";
import { makeBuyerDataList } from "../../../../redux-core/categorylist/selectors";
import { createSelector } from "reselect";
import { getAllBuyersData } from "../../../../redux-core/categorylist/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import './BuyerData.css';
import * as XLSX from 'xlsx';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const buyerSelector = createSelector(makeBuyerDataList, (buyerDataList) => ({
  buyerDataList,
}));

const actionDispatch = (dispatch) => ({
  buyerListData: (payload) => dispatch(getAllBuyersData(payload)),
});


const BuyerData = () => {
  const { buyerDataList } = useSelector(buyerSelector);
  const { buyerListData } = actionDispatch(useDispatch());
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchCategoryText, setSearchCategoryText] = React.useState("");
  const [searchLocationText, setSearchLocationText] = React.useState("");
  const [orderPlaced, setOrderPlaced] = React.useState(true);
  const [searchNameText, setSearchNameText] = React.useState("");
  const [searchMobileText, setSearchMobileText] = React.useState("");
  const [sort, setSort] = React.useState(1);
  const [sortRate, setRateSort] = React.useState(1);
  const [sortDate,setSortDate] = React.useState(1);


  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;

  useEffect(() => {
    if (
      localStorage.getItem("userToken") !== "undefined" &&
      localStorage.getItem("userToken") !== null
    ) {
      getBuyerResources(
        pageNumber,
        pageSize,
        searchCategoryText,
        searchLocationText,
        searchMobileText,
        searchNameText,
        sort,
        orderPlaced,
        sortRate,
        sortDate
      );
    }
  }, [1, pageNumber, pageSize]);

  const getBuyerResources = (
    page,
    limit,
    searchKeyForCategory,
    searchKeyForLocation,
    searchKeyForMobile,
    searchKeyForName,
    sort,
    orderPlaced,
    sortRate,
    sortDate
  ) => {
    const payload = {
      page,
      limit,
      searchKeyForCategory,
      searchKeyForLocation,
      searchKeyForMobile,
      searchKeyForName,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    };

    console.log(orderPlaced);

    buyerListData(payload);
  };

  /**
   * On change of category filter
   * @param {*} event 
   */
  const handleCategorySearchChange = (event) => {
    setSearchCategoryText(event.target.value);
    getBuyerResources(
      1,
      pageSize,
      event.target.value,
      searchLocationText,
      searchMobileText,
      searchNameText,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    );
  };

  /**
   * on change location value
   * @param {*} event 
   */
  const handleLocationSearchChange = (event) => {
    setSearchLocationText(event.target.value);
    getBuyerResources(
      1,
      pageSize,
      searchCategoryText,
      event.target.value,
      searchMobileText,
      searchNameText,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    );
  };

  /**
   * FUnction is excuted to search by name  
   * @param {*} event 
   */
  const handleNameSearchChange = (event) => {
    setSearchNameText(event.target.value);
    getBuyerResources(
      1,
      pageSize,
      searchCategoryText,
      searchLocationText,
      searchMobileText,
      event.target.value,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    );
  };

  /**
   * search by mobile number registered
   * @param {*} event 
   */
  const handleNumberSearchChange = (event) => {
    setSearchMobileText(event.target.value);
    getBuyerResources(
      1,
      pageSize,
      searchCategoryText,
      searchLocationText,
      event.target.value,
      searchNameText,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    );
  };

  /**
   * onchange toggle filter by order placed
   * @param {*} event 
   */
  const handleOrderplacedSearchChange = () => {
   // console.log(event)
    setOrderPlaced(!orderPlaced);
    getBuyerResources(
      1,
      pageSize,
      searchCategoryText,
      searchLocationText,
      searchMobileText,
      searchNameText,
      sort,
      !orderPlaced,
      sortRate,
      sortDate
    );

  };

  const handleSizeChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handlePageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  //method for changing the date format
  const getDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  };

  /**
   * sort by number of candiates
   * @param {*} e 
   */
  const handleSort = (e) => {
    getBuyerResources(
      1,
      pageSize,
      searchCategoryText,
      searchLocationText,
      searchMobileText,
      searchNameText,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    );
   
  };

  /**
   * sort by rate
   */
   const handleRateSort = (e) => {
    getBuyerResources(
      1,
      pageSize,
      searchCategoryText,
      searchLocationText,
      searchMobileText,
      searchNameText,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    );
   
  };


  /**
   * sort by rate
   */
   const handleDateSort = (e) => {
    getBuyerResources(
      1,
      pageSize,
      searchCategoryText,
      searchLocationText,
      searchMobileText,
      searchNameText,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    ); 
  };

  /**
   * columns consist of table column name and filter functions for each column respectively
   */
  const columns = [
    {
      title: "Name",
      dataIndex: "userdata",
    //   key: "userdata",
      width: 80,
      fixed: "right",
      render: (userdata) => userdata?.full_name,
    },
    {
      title: "Company",
      dataIndex: "organisation_name",
    //   key: "organisation_name",
      width: 80,
      fixed: "right",
    },
    {
      title: "Mobile",
      dataIndex: "userdata",
    //   key: "userdata",
      width: 60,
      fixed: "right",
      render: (userdata) => userdata?.mobile_number,
    },
    {
      title: "Category",
      dataIndex: "catdata",
    //   key: "catdata",
      width: 80,
      fixed: "right",
      render: (catdata) => catdata?.category_name,
    },
    {
      title: "Location",
      dataIndex: "location",
    //   key: "location",
      width: 80,
      fixed: "right",
    },
    {
      title: "Rate",
      dataIndex: "rate",
    //   key: "rate",
      width: 52,
      fixed: "right",
      sorter: (a, b) => a.rate-b.rate,
      onHeaderCell: () => ({
        onClick: () =>  handleRateSort( setRateSort(sortRate === 1 ? -1 : 1))
      })
    },
    {
      title: "Quantity",
      dataIndex: "no_of_candidates",
    //   key: "no_of_candidates",
      width: 52,
      fixed: "right",
      sorter: (a, b) => a.no_of_candidates-b.no_of_candidates,
      onHeaderCell: () => ({
        onClick: () =>  handleSort( setSort(sort === 1 ? -1 : 1))
      })
    },
    {
      title: "Date",
      dataIndex: "createdAt",
    //   key: "createdAt",
      width: 70,
      fixed: "right",
      render: (createdAt) => getDate(createdAt),
      sorter: (a, b) => a.createdAt-b.createdAt,
      onHeaderCell: () => ({
        onClick: () =>  handleDateSort( setSortDate(sortDate === 1 ? -1 : 1))
      })
    },
    {
      title: "Order Placed",
      dataIndex: "isPayment",
    //   key: "isPayment",
      width: 70,
      fixed: "right",
      render: (isPaymentText) => String(isPaymentText),
    },
  ];

  /**
   * download data from json to xlsx
   */
   const downloadData = (data,type) => {

    let finalArray = [];
    data.map((item,i) => {
      let Obj = {
        category:item.catdata.category_name,
        location:item.location,
        no_of_candiates:item.no_of_candidates,
        rate: item.rate,
        fullname:item.userdata.full_name,
        mobile:item.userdata.mobile_number
      }

      finalArray.push(Obj);
    })
    const worksheet = XLSX.utils.json_to_sheet(finalArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, type+".xlsx");
  };

  return (
    <div className="category-container">
      <Row justify="center">
        <Col className="gutter-row" span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <Input
            placeholder="Search by Category"
            size="large"
            allowClear
            style={{ width: "70%", marginBottom: "15px" }}
            onChange={handleCategorySearchChange}
          />
        </Col>
        <Col className="gutter-row" span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <Input
            placeholder="Search by Location"
            size="large"
            allowClear
            style={{ width: "70%", marginBottom: "15px" }}
            onChange={handleLocationSearchChange}
          />
        </Col>
        <Col className="gutter-row" span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <Input
            placeholder="Search by Name"
            size="large"
            allowClear
            style={{ width: "70%", marginBottom: "15px" }}
            onChange={handleNameSearchChange}
          />
        </Col>
        <Col className="gutter-row" span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <Input
            placeholder="Search by Number"
            size="large"
            allowClear
            style={{ width: "70%", marginBottom: "15px" }}
            onChange={handleNumberSearchChange}
          />
        </Col>

        <Col className="gutter-row"style={{marginTop:"10px"}}   span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <label>Order Placed: </label>
        <Switch onChange={handleOrderplacedSearchChange} checkedChildren="Yes" unCheckedChildren="No" defaultChecked />

        </Col>
        <Col className="gutter-row"style={{marginTop:"10px"}}   span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
        <Button onClick={() => downloadData(buyerDataList?.data,"demand")} style={{margin:"0px 20px"}}>Export Demand </Button>
        </Col>
      </Row>
      {buyerDataList?.length === 0 ? (
        <>
          <Skeleton active />
        </>
      ) : (
        <>

          <Table
          className="buyerDataTable"
            rowsel
            rowKey={"_id"}
            columns={columns}
            dataSource={buyerDataList?.data}
            bordered
            size="middle"
            pagination={false}
            scroll={{ x: 100, y: width > 320 ? height * 0.47 : height * 0.35 }}
          />
          <br></br>
        </>
      )}

      <div style={{ marginTop: "1.6rem" }}>

        <Pagination
          pageSizeOptions={["10", "20", "50", "100", "200", "500", "1000"]}
          showSizeChanger
          defaultCurrent={1}
          total={buyerDataList?.totalItems ? buyerDataList?.totalItems : 0}
          onShowSizeChange={handleSizeChange}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default BuyerData;
