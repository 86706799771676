import {
  Form,
  Input,
  InputNumber,
  Pagination,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  deleteLocation,
  getLocations,
  updateLocation,
} from "../../redux-core/locationrequest/actions";
import { makeSelectLocationList } from "../../redux-core/locationrequest/selectors";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const getLocationSelector = createSelector(
  makeSelectLocationList,
  (locationList) => ({
    locationList,
  })
);

const actionDispatch = (dispatch) => ({
  getLocationListCall: (payload) => dispatch(getLocations(payload)),
  handleDeleteLocation: (payload) => dispatch(deleteLocation(payload)),
  updateLocationItem: (payload) => dispatch(updateLocation(payload)),
});

const EditCity = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const { locationList } = useSelector(getLocationSelector);
  const { getLocationListCall, handleDeleteLocation, updateLocationItem } =
    actionDispatch(useDispatch());

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchText, setSearchText] = React.useState("");
  const [data, setData] = useState(locationList?.data);

  const isEditing = (record) => record._id === editingKey;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  /**
   * Load the list of location list on pagination load
   */
  useEffect(() => {
    handleGetLocation(pageNumber, pageSize, searchText);
  }, [1, pageNumber, pageSize]);

  const handleGetLocation = (page, limit, searchKeyForLocation) => {
    const payload = {
      page,
      limit,
      searchKeyForLocation,
    };
    getLocationListCall(payload);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    if(event.target.value.length>=3){
      handleGetLocation(1, pageSize, event.target.value);
    }
  };

  const handleSizeChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handlePageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  /**
   * On Edit make save and cancel button visible
   * make invisible Edit
   * Set fields with Input field
   * @param {*} record
   */
  const edit = (record) => {
    form.setFieldsValue({
      city: "",
      lat: "",
      long: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  /**
   * On cancel close the is edit
   */
  const cancel = () => {
    setEditingKey("");
  };

  /**
   * On save get the details from Input and submit to server
   * @param {*} key
   * Update state and append the values to table
   */
  const save = async (key, createdAt, updatedAt) => {
    try {
      const row = await form.validateFields();
      const newData = [...locationList?.data];
      const index = newData.findIndex((item) => key === item._id);

      const rowData = {
        _id: key,
        city: row.city,
        lat: row.lat,
        long: row.long,
        createdAt: createdAt,
        updatedAt: updatedAt,
        page: pageNumber,
        limit: pageSize,
        searchKeyForLocation: searchText,
      };

      updateLocationItem(rowData);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deletelocation = (record) => {
    const payload = {
      id: record._id,
      page: pageNumber,
      limit: pageSize,
      searchKeyForLocation: searchText,
    };
    handleDeleteLocation(payload);
  };

  /**
   * Column names with data index values
   */
  const columns = [
    {
      title: "City Name",
      dataIndex: "city",
      editable: true,
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      editable: true,
    },
    {
      title: "Longitude",
      dataIndex: "long",
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure you want to save the changes?"
              onConfirm={() =>
                save(record._id, record.createdAt, record.updatedAt)
              }
            >
              <a
                href="javascript:;"
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </a>
            </Popconfirm>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="category-edit-button-style">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure you want to delete this category?"
              onConfirm={() => deletelocation(record)}
            >
              <Typography.Link
                style={{ color: "#f11012" }}
                disabled={editingKey !== ""}
              >
                Delete
              </Typography.Link>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  /**
   * Column names and actions
   */
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "price" ||
          col.dataIndex === "minimum" ||
          col.dataIndex === "maximum"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  /**
   * Editcell data action
   * @param {*} param0
   * @returns
   */
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    const inputNodeit = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={
              dataIndex === "category_name"
                ? { width: 250 }
                : dataIndex === "unit" || dataIndex === "price"
                ? { width: 50 }
                : { width: 100 }
            }
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {dataIndex !== "category_name" && dataIndex !== "price"
              ? inputNode
              : inputNodeit}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      <Input
        placeholder="Search by City Name"
        size="large"
        allowClear
        style={{ width: "90%", marginBottom: "15px" }}
        onChange={handleSearchChange}
      />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={locationList?.data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
          scroll={{ x: 100, y: width > 570 ? height * 0.5 : height * 0.55 }}
        />
      </Form>
      <br></br>
      <div style={{ marginTop: "1.6rem" }}>
        <Pagination
          pageSizeOptions={["10", "20", "50", "100", "200", "500", "1000"]}
          showSizeChanger
          defaultCurrent={1}
          total={locationList?.totalItems ? locationList?.totalItems : 0}
          onShowSizeChange={handleSizeChange}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default EditCity;
