import React from "react";
import { Tabs } from "antd";
import Matched from "./matched/Matched";
import { redirectAction } from "../../redux-core/login/actions";
import { useDispatch } from "react-redux";

const { TabPane } = Tabs;

const actionDispatch = (dispatch) => ({
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const MatchPage = (props) => {
  const { redirect } = actionDispatch(useDispatch());

  if (
    localStorage.getItem("userToken") === "undefined" ||
    localStorage.getItem("userToken") === null
  ) {
    redirect(props, "/login");
  }
  return (
    <div>
      <div className="heading">
        <h2>Match Page</h2>
      </div>

      <Tabs defaultActiveKey="2" className="matchTab" centered>
        <TabPane tab={"Matched"} key="2" forceRender={true}>
          <Matched />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MatchPage;
