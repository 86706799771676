import { Button, Card, Form, Input } from "antd";
import React from "react";
import "./SendNotification.css";
import { redirectAction } from "../../redux-core/login/actions";
import { useDispatch } from "react-redux";
import { sendNotification } from "../../redux-core/sendnotification/actions";

const actionDispatch = (dispatch) => ({
  postNotification: (payload) => dispatch(sendNotification(payload)),
});

const SendNotification = () => {
  const { postNotification } = actionDispatch(useDispatch());
  const formRef = React.createRef();

  const pushNotification = (values) => {
    console.log("Success:", values);
    postNotification(values);
    formRef.current.setFieldsValue({ title: '', description: '' });
  };

  return (
    <div className="send-notification">
      <Card hoverable={true} className="notificationFormCard">
        <Form onFinish={pushNotification} ref={formRef}>
          <Form.Item
            label="Title"
            name="title"
            required
            rules={[{ required: true, message: "Please enter Title" }]}
          >
            <Input placeholder="Please enter Title" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            required
            rules={[{ required: true, message: "Please enter Description" }]}
          >
            <Input placeholder="Please enter Description" />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SendNotification;
