import { Col, Input, Pagination, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getCategories } from "../../../redux-core/categorylist/actions";
import { getMatchedReportData } from "../../../redux-core/matchingreport/action";
import { makeMatchedReportList } from "../../../redux-core/matchingreport/selectors";
import moment from 'moment';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const actionDispatch = (dispatch) => ({
  matchedReportList: (payload) => dispatch(getMatchedReportData(payload)),
});

const matchedReportDataSelector = createSelector(
  makeMatchedReportList,
  (matchedReportData) => ({
    matchedReportData,
  })
);

const Matched = () => {
  const { matchedReportList } = actionDispatch(useDispatch());
  const { matchedReportData } = useSelector(matchedReportDataSelector);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchCategoryText, setSearchCategoryText] = React.useState("");


  const { height, width } = windowDimensions;

  useEffect(() => {
    if (
      localStorage.getItem("userToken") !== "undefined" &&
      localStorage.getItem("userToken") !== null
    ) {
      getCategoryDetails(
        pageNumber,
        pageSize,
        searchCategoryText,
      );
    }
  }, [1, pageNumber, pageSize]);
  
//method for changing the date format
const getMatchingDate=(date)=>{
   return moment(date).format("DD-MM-YYYY");
}

  /**
   * columns consist of table column for buyer,seller info respectively
  */
 
   const columns = [
    {
      title: "Buyer Name",
      dataIndex: "buyer_name",
      key: "buyer_name",
      width: 80,
      fixed: "right",
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      width: 80,
      fixed: "right",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 80,
      fixed: "right",
    },
    {
      title: "Category",
      dataIndex: "catdata",
      key: "catdata",
      width: 80,
      fixed: "right",
      render:(catdata)=>catdata?.category_name,
    },
    {
      title: "Rate",
      dataIndex: "price_match",
      key: "price_match",
      width: 70,
      fixed: "right",
    },
    {
      title: "Quantity",
      dataIndex: "no_of_candidates",
      key: "no_of_candidates",
      width: 50,
      fixed: "right",
    },
    {
      title: "Matching Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 70,
      fixed: "right",
      render: ((createdAt) => getMatchingDate(createdAt))
    },
  ];

  const getCategoryDetails = (
    page,
    limit,
    searchKeyForCategory,
  ) => {
    const payload = {
      page,
      limit,
      searchKeyForCategory,
    };
    matchedReportList(payload);
  };

  const handleCategorySearchChange = (event) => {
    setSearchCategoryText(event.target.value);
    if(event.target.value.length>=3){
      getCategoryDetails(1, pageSize, event.target.value);
    }
  };

  const handleSizeChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handlePageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  return (
    <>
      {/* <div className="heading">
        <h2>Matched Data</h2>
      </div> */}

      <Row justify="center">
        <Col className="gutter-row" span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <Input
            placeholder="Search by Category"
            size="large"
            allowClear
            style={{ width: "90%", marginBottom: "15px" }}
            onChange={handleCategorySearchChange}
          />
        </Col>
      </Row>
      
      <>
          <Table
            rowsel
            rowKey={"matched_data"}
            columns={columns}
            dataSource={matchedReportData?.data}
            bordered
            size="middle"
            pagination={false}
            scroll={{ x: 100, y: width > 320 ? height * 0.47 : height * 0.35 }}
          />
          <br></br>
        </>
        <div style={{ marginTop: "1.6rem" }}>
        <Pagination
          pageSizeOptions={["10", "20", "50", "100", "200", "500", "1000"]}
          showSizeChanger
          defaultCurrent={1}
          total={matchedReportData?.totalItems ? matchedReportData?.totalItems : 0}
          onShowSizeChange={handleSizeChange}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default Matched;
