import { Button, Col, Input, Pagination, Radio, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getAllBuyersData,
  getAllSellersData,
} from "../../../redux-core/categorylist/actions";
import {
  makeBuyerDataList,
  makeSellerDataList,
} from "../../../redux-core/categorylist/selectors";
import "antd/dist/antd.css";
import "./MatchResources.css";
import * as XLSX from 'xlsx';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const sellerSelector = createSelector(makeSellerDataList, (sellerDataList) => ({
  sellerDataList,
}));

const buyerSelector = createSelector(makeBuyerDataList, (buyerDataList) => ({
  buyerDataList,
}));

const actionDispatch = (dispatch) => ({
  sellerListData: (payload) => dispatch(getAllSellersData(payload)),
  buyerListData: (payload) => dispatch(getAllBuyersData(payload)),
});

const MatchResources = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;

  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageNumberseller, setPageNumberseller] = React.useState(1);
  const [pageSizeseller, setPageSizeseller] = React.useState(10);
  const [sort, setSort] = React.useState(1);
  const [sortRate, setRateSort] = React.useState(1);
  const [orderPlaced, setOrderPlaced] = React.useState(true);
  const [searchCategoryText, setSearchCategoryText] = React.useState("");
  const [searchLocationText, setSearchLocationText] = React.useState("");
  const [searchCategoryTextSeller, setSearchCategoryTextSeller] = React.useState("");
  const [searchLocationTextSeller, setSearchLocationTextSeller] = React.useState("");
  const { sellerDataList } = useSelector(sellerSelector);
  const { buyerDataList } = useSelector(buyerSelector);
  const { sellerListData, buyerListData } = actionDispatch(useDispatch());
  const [rowColor, SetRowColor] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const [sortDate, SetSortDate] = React.useState(1);

  useEffect(() => {
    if (
      localStorage.getItem("userToken") !== "undefined" &&
      localStorage.getItem("userToken") !== null
    ) {
      getSellerResources(
        pageNumberseller,
        pageSizeseller,
        searchCategoryTextSeller,
        searchLocationTextSeller
      );
      getBuyerResources(
        pageNumber,
        pageSize,
        searchCategoryText,
        searchLocationText,
        sort,
        orderPlaced,
        sortRate
      );
    }
  }, [1, pageNumber, pageSize, pageNumberseller, pageSizeseller]);

  const getBuyerResources = (
    page,
    limit,
    searchKeyForCategory,
    searchKeyForLocation,
    sort,
    orderPlaced,
    sortRate
  ) => {
    const payload = {
      page,
      limit,
      searchKeyForCategory,
      searchKeyForLocation,
      sort,
      orderPlaced,
      sortRate,
      sortDate
    };

    buyerListData(payload);
  };

  const getSellerResources = (
    page,
    limit,
    searchKeyForCategory,
    searchKeyForLocation
  ) => {
    const payload = {
      page,
      limit,
      searchKeyForCategory,
      searchKeyForLocation,
      sort,
      sortDate
    };
    sellerListData(payload);
  };

  const handleCategorySearchChange = (event) => {
    setSearchCategoryText(event.target.value);
    getSellerResources(
      1,
      pageSize,
      event.target.value,
      searchLocationText,
      sort
    );
  };

  const handleLocationSearchChange = (event) => {
    setSearchLocationText(event.target.value);
    getSellerResources(
      1,
      pageSize,
      searchCategoryText,
      event.target.value,
      sort
    );
  };

  const handleSizeChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handlePageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handleSizeChangeSeller = (current, pageSize) => {
    setPageNumberseller(current);
    setPageSizeseller(pageSize);
  };

  const handlePageChangeseller = (current, pageSize) => {
   setPageNumberseller(current);
    setPageSizeseller(pageSize);
  };

  /**
   * on row select
   */
  const myRowSelection = {
    selectedRowKeys: selectedKeys,
    onSelect: (record, selected) => {
      setSearchCategoryTextSeller(record.catdata.category_name);
      setSearchLocationTextSeller(record.location);
      getSellerResources(
        1,
        pageSize,
        record.catdata.category_name,
        record.location,
        sort,
        sortDate
      );
    }
  };


   /**
   * sort by rate
   */
    const handleRateSort = (e) => {
      getBuyerResources(
        1,
        pageSize,
        searchCategoryText,
        searchLocationText,
        sort,
        orderPlaced,
        sortRate
      );
     
    };

  /**
   * columns consist of table column name and filter functions for each column respectively
   */
  const columns = [
    {
      title: "Category",
      dataIndex: "catdata",
      key: "catdata",
      width: 80,
      fixed: "right",
      render: (catdata) => catdata?.category_name
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 80,
      fixed: "right",
    },
    {
      title: "Buyer Name",
      dataIndex: "userdata",
      //   key: "userdata",
      width: 80,
      fixed: "right",
      render: (userdata) => userdata?.full_name,
    },
    {
      title: "Buyer Mobile",
      dataIndex: "userdata",
      //   key: "userdata",
      width: 60,
      fixed: "right",
      render: (userdata) => userdata?.mobile_number,
    },
    {
      title: "Buyer Rate",
      dataIndex: "rate",
      key: "rate",
      width: 40,
      fixed: "right",
      sorter: (a, b) => a.rate-b.rate,
      onHeaderCell: () => ({
        onClick: () =>  handleRateSort( setRateSort(sortRate === 1 ? -1 : 1))
      })
    },
    {
      title: "Buyer Qty",
      dataIndex: "no_of_candidates",
      key: "no_of_candidates",
      width: 30,
      fixed: "right",
    },
  ];

  const sellercolumns = [
    {
      title: "Seller Name",
      dataIndex: "userdata",
      key: "userdata",
      width: 20,
      fixed: "right",
      render: (userdata) => userdata?.full_name,
    },
    {
      title: "Seller Mobile",
      dataIndex: "userdata",
      key: "userdata",
      width: 20,
      fixed: "right",
      render: (userdata) => userdata?.mobile_number,
    },
    {
      title: "Seller Rate",
      dataIndex: "rate",
      key: "rate",
      width: 10,
      fixed: "right",
    },
    {
      title: "Seller Qty",
      dataIndex: "no_of_candidates",
      key: "no_of_candidates",
      width: 10,
      fixed: "right",
    },
  ];

  /**
   * row selection
   */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSearchCategoryTextSeller(record.catdata.category_name);
      setSearchLocationTextSeller(record.location);
      getSellerResources(
        1,
        pageSize,
        record.catdata.category_name,
        record.location,
        sort,
        sortDate
      );
    },
  
  };

  /**
   * download data from json to xlsx
   */
  const downloadData = (data,type) => {

    let finalArray = [];
    data.map((item,i) => {
      let Obj = {
        category:item.catdata.category_name,
        location:item.location,
        no_of_candiates:item.no_of_candidates,
        rate: item.rate,
        fullname:item.userdata.full_name,
        mobile:item.userdata.mobile_number
      }

      finalArray.push(Obj);
    })
    const worksheet = XLSX.utils.json_to_sheet(finalArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, type+".xlsx");
  };
  return (
    <>
    <div className="category-container">
      <Row justify="center">
        <Col className="gutter-row" span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <Input
            placeholder="Category"
            size="large"
            allowClear
            style={{ width: "70%", marginBottom: "15px" }}
            onChange={handleCategorySearchChange}
          />
        </Col>
        <Col className="gutter-row" span={{ xs: 12, sm: 12, md: 24, lg: 32 }}>
          <Input
            placeholder="Location"
            size="large"
            allowClear
            style={{ width: "70%", marginBottom: "15px" }}
            onChange={handleLocationSearchChange}
          />
        </Col>
      </Row>
      <Row style={{marginBottom:"10px"}}>
          <Col md={12}>
            <Button onClick={() => downloadData(buyerDataList?.data,"demand")}>Export Demand </Button>
          </Col>
          <Col md={12}>
            <Button onClick={() => downloadData(sellerDataList?.data,"supply")}>Export Supply </Button>
          </Col>
        </Row>
      <div className="buyer-seller-columns">
      

        <Table
          className="buyerTable"
          rowSelection={{type:'radio',...rowSelection}}
          rowKey={"_id"}
          columns={columns}
          dataSource={buyerDataList?.data}
          bordered
          size="middle"
          pagination={false}
          width="75%"
          scroll={{ x: 100, y: width > 320 ? height * 0.47 : height * 0.35 }}
        />
        <Table
          className="sellerTable"
          rowsel
          rowKey={"_id"}
          columns={sellercolumns}
          dataSource={sellerDataList?.data}
          bordered
          size="middle"
          pagination={false}
          scroll={{ x: 100, y: width > 320 ? height * 0.47 : height * 0.35 }}
        />
        <br></br>
      </div>

      <div style={{ marginTop: "1.6rem", float:"left", marginLeft:"5rem" }}>
        
        
      </div>
      
      <div style={{ marginTop: "1.6rem", marginLeft:"0.5rem", float:"left" }}>

   
      <Row>
          <Col md={12}>
          <Pagination
          pageSizeOptions={["10", "20", "50", "100", "200", "500", "1000"]}
          showSizeChanger
          defaultCurrent={1}
          total={
          
            buyerDataList?.totalItems
                ? buyerDataList?.totalItems
                : 0
             
          }
          onShowSizeChange={handleSizeChange}
          onChange={handlePageChange}
        />          </Col>
          <Col md={12}>
          <Pagination
          pageSizeOptions={["10", "20", "50", "100", "200", "500", "1000"]}
          showSizeChanger
          defaultCurrent={1}
          total={
          
            sellerDataList?.totalItems
            ? sellerDataList?.totalItems
            : 0
          }         
          onShowSizeChange={handleSizeChangeSeller}
          onChange={handlePageChangeseller}
        />          </Col>
        </Row>
      
        </div>
    </div>
   
</>
    
  );
};

export default MatchResources;
