import React, { useEffect, useState } from "react";
import "./Resources.css";
import { Tabs } from "antd";
import ResourceWithLocation from "./resourcewithlocation/ResourceWithLocation";
import AllResources from "./allresources/AllResources";
import { redirectAction } from "../../redux-core/login/actions";
import { useDispatch } from "react-redux";
import { getCategories } from "../../redux-core/categorylist/actions";
import MatchResources from "./matchresources/MatchResources";

const { TabPane } = Tabs;

const actionDispatch = (dispatch) => ({
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const Resources = (props) => {
  const { redirect } = actionDispatch(useDispatch());


  if (
    localStorage.getItem("userToken") === "undefined" ||
    localStorage.getItem("userToken") === null
  ) {
    redirect(props, "/login");
  }

  return (
    <div>
      <Tabs defaultActiveKey="2" className="DashboardTab" centered>
        <TabPane tab={"Resource With Location"} key="2" forceRender={true}>
          <ResourceWithLocation />
        </TabPane>
        <TabPane tab={"All Resources"} key="3">
          <AllResources />
        </TabPane>
        <TabPane tab={"Match Resources"} key="4">
          <MatchResources/>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Resources;
