import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getSuperAdminUserDetailList } from "../../redux-core/usermanagement/actions";
import { makeSelectSuperAdminUserList } from "../../redux-core/usermanagement/selectors";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const superAdminUserDetailsListSelector = createSelector(makeSelectSuperAdminUserList, (superAdminUserListDetails) => ({
    superAdminUserListDetails,
}));

const columns = [
    {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'name',
    }, {
        title: 'Mobile',
        dataIndex: 'mobile_number',
        key: 'mobile',
    }, {
        title: 'Address',
        dataIndex: 'permanent_address',
        key: 'address',
    }, {
        title: "Email",
        dataIndex: "email",
        editable: true,
        key: 'email',
    }
];

const actionDispatch = (dispatch) => ({
    getSuperAdminUserListDetails: () => dispatch(getSuperAdminUserDetailList()),
});

const SuperAdminUser = (props) => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { height, width } = windowDimensions;

    const { superAdminUserListDetails } = useSelector(superAdminUserDetailsListSelector);

    const { getSuperAdminUserListDetails } = actionDispatch(useDispatch());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions])

    useEffect(() => {
        getSuperAdminUserListDetails()
    }, [])

    return (
        <>
            <Table
                bordered
                dataSource={superAdminUserListDetails?.data}
                columns={columns}
                rowClassName="editable-row"
                size="middle"
                pagination={false}
                scroll={{ x: 100, y: width > 570 ? height * 0.50 : height * 0.90 }}
            />
        </>
    )
}

export default SuperAdminUser;