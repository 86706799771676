import { Button, Pagination, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getAllLocationReqData,
  updateLocationsRequest,
} from "../../redux-core/locationrequest/actions";
import { makeSelectLocationRequestList } from "../../redux-core/locationrequest/selectors";
import { redirectAction } from "../../redux-core/login/actions";
import AddCity from "./AddCity";
import EditCity from "./EditCity";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const locationRequestSelector = createSelector(
  makeSelectLocationRequestList,
  (locationReqData) => ({
    locationReqData,
  })
);

const actionDispatch = (dispatch) => ({
  redirect: (props, path) => dispatch(redirectAction(props, path)),
  locationReqList: (payload) => dispatch(getAllLocationReqData(payload)),
  updateLocationList: (id) => dispatch(updateLocationsRequest(id)),
});

const CityRequest = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;
  const { TabPane } = Tabs;

  const { locationReqData } = useSelector(locationRequestSelector);
  const { redirect, locationReqList, updateLocationList } = actionDispatch(
    useDispatch()
  );
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [activeKey, setActiveKey] = React.useState("1");
  const [locationUserRequestID, setLocationUserRequestID] =
    React.useState(undefined);
  const [locationRequestName, setLocationRequestName] =
    React.useState(undefined);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      redirect(props, "/login");
      return;
    }
    if (localStorage.getItem("userToken")) {
      getCityRequest(pageNumber, pageSize);
    }
  }, [1, pageNumber, pageSize]);

  const getCityRequest = (page, limit) => {
    const payload = {
      page,
      limit,
    };
    locationReqList(payload);
  };

  const handleSizeChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handlePageChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  /**
   * Button action for accept city request
   * @param {*} key
   */
  const handleAcceptLocation = (id, locationName) => {
    setLocationUserRequestID(id);
    setLocationRequestName(locationName);
    setActiveKey("2");
    const payload = {
      objectID: id,
      page: pageNumber,
      limit: pageSize,
      status: "accept",
    };
    updateLocationList(payload);
  };

  const handleRejectLocation = (key) => {
    const payload = {
      objectID: key,
      page: pageNumber,
      limit: pageSize,
      status: "reject",
    };
    updateLocationList(payload);
  };

  /**
   * Colum list with dataindex
   */
  const columns = [
    {
      title: "User Name",
      dataIndex: "full_name",
      editable: true,
      width: 50,
    },
    {
      title: "City Name",
      dataIndex: "location_name",
      editable: true,
      width: 50,
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <div className="category-button-ghost-wrapper">
            <Button
              className="category-button-ghost-style"
              type="primary"
              ghost
              onClick={() =>
                handleAcceptLocation(record._id, record.location_name)
              }
            >
              Accept
            </Button>
            <Button
              className="category-button-ghost-style"
              type="danger"
              ghost
              onClick={() => handleRejectLocation(record._id)}
            >
              Reject
            </Button>
          </div>
        );
      },
    },
  ];

  const handleTabsChange = (activeKey) => {
    setActiveKey(activeKey);
    setLocationUserRequestID(undefined);
    setLocationRequestName(undefined);
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        centered
        activeKey={activeKey}
        onChange={handleTabsChange}
      >
        <TabPane tab="City Request" key="1">
          <Table
            bordered
            columns={columns}
            dataSource={locationReqData?.data}
            size="middle"
            pagination={false}
            scroll={{ x: 100, y: width > 570 ? height * 0.5 : height * 0.55 }}
          />
          <br></br>
          <div style={{ marginTop: "1.6rem" }}>
            <Pagination
              pageSizeOptions={["10", "20", "50", "100", "200", "500", "1000"]}
              showSizeChanger
              defaultCurrent={1}
              total={
                locationReqData?.totalItems ? locationReqData?.totalItems : 0
              }
              onShowSizeChange={handleSizeChange}
              onChange={handlePageChange}
            />
          </div>
        </TabPane>
        <TabPane tab="Add City" key="2">
          <AddCity locationName={locationRequestName} />
        </TabPane>
        <TabPane tab="Edit City" key="3">
          <EditCity />
        </TabPane>
      </Tabs>
    </>
  );
};

export default CityRequest;
